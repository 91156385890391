<template>
    <div id="Particulars">
      <div class="particularsContent">
        <div class="particularsSide"/>
        <div class="content">
          <div class="title">
            <h2>{{ this.data.title}}</h2>
          </div>
          <div class="ql-container ql-snow">
              <div class="ql-editor" v-html="data.content" style="margin: 20px" />
            </div>
          <div class="footer"/>
        </div>
      </div>
    </div>
</template>

<script>
import utils from '@/config/config.js'
export default {
  name: 'Particulars',
  props: {
  },
  data () {
    return {
      blogId: '',
      data: {
      }
    }
  },
  mounted () {
    this.blogId = this.$route.query.blogId
    this.getInfo()
  },
  methods: {
    getInfo () {
      const _this = this
      let parameter = {
        'blogId': this.blogId
      }
      $.ajax({
        type: 'GET',
        url: utils.url + '/api/blog/getBlog',
        data: parameter,
        success: function (data, state, ret) {
          if (ret.status === 200) {
            if (data.code === 200) {
              _this.data = data.data
            } else {
              _this.$message.error(data.msg)
            }
          }
        },
        beforeSend: utils.beforeSend,
        xhrFields: utils.xhrFields
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #Particulars {
    width: 100%;
    display: flex;
  }
  .particularsContent {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .particularsSide {
    width: 10vw;
    height: calc(100vh - 140px);
    margin: 0;
    padding: 0;
    float: left;
  }
  .content {
    width: 80vw;
    float: left;
    margin: 20px 0px;
    background: #ffffff;
    border-radius:  30px 30px 30px 30px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title {
    margin: 10px 0 0 0;
  }
  .footer {
    height: 40px;
  }
</style>
